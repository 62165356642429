import React, { useState } from 'react';
import { db,storage  } from './firebase';
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import logo from './logo.png';
import './BusinessForm.css';

const BusinessForm = () => {
  const [newBusiness, setNewBusiness] = useState({
    Featured:false,
    highlight: '',
    Highlight: false,
    approved:false,
    adBannerURL: null,
    iconImageURL: null,
    name: '',
    address: '',
    phone: '',
    email: '',
    website: '',
    contactInfo: '',
    category: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (e) => {
    setNewBusiness({ ...newBusiness, [e.target.name]: e.target.value });
  };

  const handleHighlightChange = (e) => {
    setNewBusiness({ ...newBusiness, highlight: e.target.checked });
  };

  const uploadImg = async (event) => {
    const file =event.target.files[0];
    if (!file) return null;

    const fileRef = ref(storage, `images/${file.name}`);
    const snapshot = await uploadBytes(fileRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    setNewBusiness({ ...newBusiness, [event.target.name]: downloadURL });
    // return downloadURL;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    //   const adBannerUrl = await uploadImg(newBusiness.adBannerFile);
    //   console.log(newBusiness.adBannerFile);
    //   const iconImageUrl = await uploadImg(newBusiness.iconImageFile);
      await addDoc(collection(db, 'Businesses', 'common', 'businesses'), newBusiness);

      setNewBusiness({
        Featured:false,
        highlight: '',
        Highlight: false,
        approved:false,
        adBannerURL: null,
        iconImageURL: null,
        name: '',
        address: '',
        phone: '',
        email: '',
        website: '',
        contactInfo: '',
        category: '',
      });
      setIsSubmitted(true); 
    } catch (error) {
      console.error('Error adding business: ', error);
      alert('Error adding business');
    }
  };

  function isMobileOrTablet() {
     // User agent strings to check
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Regular expression for mobile devices
  const mobileRegex = /iPhone|iPod|android|blackberry|mini|windows\sce|palm/i;

  // Check if the user agent string matches mobile devices
  return mobileRegex.test(userAgent);
  }
  
  const result = isMobileOrTablet();
  
  // if(!result){
  //   return (
  //     <div style={{display:'block',margin:'auto',width:'70%',textAlign:'center',padding:'30px',marginTop:'30px',border:'1px solid green',borderRadius:'40px',backgroundColor:'red',color:'white'}}>
  //       <h2>Please Use Mobile for Excess This Page</h2>
  //     </div>
  //   );
  // }

  if (isSubmitted) {
    // Render a card with a success message
    return (
      <div className="submission-card">
        <h2>Your application is submitted</h2>
        <p>Please wait for approval.</p>
      </div>
    );
  }

  return (
    <div className="business-form">
      <div className="header">
        <img src={logo} alt="" width={100} style={{}}/>
        <h1>Add My Business</h1>
      </div>
    
    <form className='form' onSubmit={handleSubmit}>
      <div className='input-container'>
        <label htmlFor="">Business Name</label>
        <input
          type="text"
          name="name"
          value={newBusiness.name}
          onChange={handleInputChange}
          className="form-input"
          required
        />
      </div>  


      <div className='input-container'>
      <label htmlFor="">Business Address</label>
      <input
        type="text"
        name="address"
        value={newBusiness.address}
        onChange={handleInputChange}
        className="form-input"
        required
      />
    </div>

    <div className='input-container'>
    <label htmlFor="">Business Phone</label>
    <input
      type="text"
      name="phone"
      value={newBusiness.phone}
      onChange={handleInputChange}
      className="form-input"
      required
    />
    </div>

      <div className='input-container'>
        <label htmlFor="">Business Email</label>
        <input
          type="text"
          name="email"
          value={newBusiness.email}
          onChange={handleInputChange}
          className="form-input"
          required
        />
      </div>

      <div className='input-container'>
      <label htmlFor="">Business Website URL</label>
      <input
      type="text"
      name="website"
      value={newBusiness.website}
      onChange={handleInputChange}
      className="form-input"
      required
      />
      </div>
      
      <div className='input-container'>
        <label htmlFor="">Business Category</label>
        <input
          type="text"
          name="category"
          value={newBusiness.category}
          onChange={handleInputChange}
          className="form-input"
          required
        />
      </div>

      <div className='input-container'>
      <label htmlFor="">About Your Business</label>
      <textarea
        type="text"
        rows={4}
        cols={50}
        name="highlight"
        value={newBusiness.highlight}
        onChange={handleInputChange}
        className="form-input"
        required
      />
      </div>
      

      <div className='input-container'>
      <label htmlFor="adBannerFile">Ad Banner:</label>
      <input
        type="file"
        name="adBannerFile"
        id="adBannerFile" 
        onChange={uploadImg}
        className="form-input"
        style={{outline:'1px solid #107979',margin:'1px 0'}}
        required
      />
    </div>
    
    <div className='input-container'>
      <label htmlFor="iconImageFile">Icon Image:</label>
      <input
        type="file"
        id="iconImageFile" 
        name="iconImageFile"
        onChange={uploadImg}
        className="form-input"
        style={{outline:'1px solid #107979',margin:'1px 0'}}
        required
      />
    </div>

      <button type="submit" className="submit-button">Add My Business</button>
    </form>
  </div>
  );
};

export default BusinessForm;
