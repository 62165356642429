
import './App.css';
import BusinessForm from './BusinessForm';

function App() {
  return (
    <div style={{}}>
      <BusinessForm></BusinessForm>
    </div>
  );
}

export default App;
